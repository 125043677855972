import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { TagCloud } from "react-tagcloud";
import { headingFamily } from "../theme";
import { getTagPagePath } from "../tagPathUtils";
import PageBanner from "../components/PageBanner/PageBanner";
import FeaturedEvents from "../components/FeaturedEvents/FeaturedEvents";
import PageSection from "../components/PageSection/PageSection";
import withLayoutAndData from "./generic/withLayoutAndData";

const StyledTagCloud = styled(TagCloud)`
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Tag = styled(Link)`
  margin: 10px;
  ${headingFamily}
  font-size: ${({ size }) => size}px;
  color: ${({ color }) => color};
  position: relative;
  cursor: pointer;
`;

const TagsIndexPage = ({ page, highlightedEvents }) => {
	const tagRenderer = (tag, fontSize, color) =>
		<Tag
			key={tag.value}
			size={fontSize}
			color={color}
			to={getTagPagePath(page.tagContentType, tag.value)}>
			{tag.value}
		</Tag>;

	return (<div>
		<PageBanner title={page.title}
			image={page.image}
			imageYPosition={page.imageYPosition} />

		<PageSection bottomBorder>
			<StyledTagCloud tags={page.tags}
				maxSize={58} minSize={28}
				renderer={tagRenderer} />
		</PageSection>

		<PageSection>
			<FeaturedEvents items={highlightedEvents} page={`${page.slug}tags`} />
		</PageSection>
	</div>);
};

export const tagPageQuery = graphql`
  query TagsIndexQuery($contentType: String, $sectionType: String) {
     markdownRemark(frontmatter: { templateKey: { eq: $sectionType } }) {
      frontmatter {
        title
      }
	  fields {
				slug
			}
      ...RemoteBannerImage
    }
	home: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
      }
      ...RemoteBannerImage
    }
  allMarkdownRemark(limit: 1000,
      filter: {frontmatter: {templateKey: {eq: $contentType}}}) {
        tags: group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 3) {
    edges {
      ...EventHighlight
    }
  }
}`;

export default withLayoutAndData(
	({ data, pageContext }) => {

		const markdown = data.markdownRemark || data.home;

		const page = {
			...markdown.frontmatter,
			...markdown.fields,
			image: markdown.image,
			tagContentType: pageContext.contentType,
			tags: data.allMarkdownRemark.tags
				.map((t) => ({ value: t.fieldValue, count: t.totalCount })),
		};

		page.title = `תגיות ל-${page.title}`;

		return {
			page,
			highlightedEvents: data.highlightedEvents,
		};
	}
)(TagsIndexPage);
