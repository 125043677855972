//using commonjs module because this file also used by gatsby-node

const kebabCase = require("lodash/kebabCase");

const tagsPagesNoPlural = ["blog", "library"];

const getTagPageType = (template) => {
	let type = template.split("-")[0];

	if (!~tagsPagesNoPlural.indexOf(type)) {
		type = type + "s"; //naively pluralize.../
	}

	return type;
};

const getTagPagePath = (template, tag) => {
	const type = getTagPageType(template);
	return `/${type}/tags/${kebabCase(tag)}`;
};

const getTagsIndexPath = (template) => {
	const type = getTagPageType(template);
	return `/${type}/tags`;
};

const getTagsSectionIndexPath = (template) => {
	const type = getTagPageType(template);
	return `${type}-index-page`;
};

module.exports = {
	getTagPagePath,
	getTagsIndexPath,
	getTagsSectionIndexPath,
};
